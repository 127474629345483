import $ from "jquery";
import {active} from "./const.js";

// トップへ戻るボタン
const donation = $('#js-donation');
donation.hide()
//スクロールが100に達したらボタン表示
$(window).scroll(function () {
  if($(this).scrollTop() > 100) {
    donation.fadeIn();
  } else {
    donation.fadeOut();
  }
});
